import {PartnershipChoiceSchema} from './Partnership';
import {TypeCountSchema} from '../features/interfaces';
import z from 'zod';

export const PartnershipDetailsSchema = PartnershipChoiceSchema.extend({
    aboutUs: z.array(z.record(z.string(), z.string())),
    logo: z.string(),
    address: z.string(),
    zipCode: z.string(),
    website: z.string(),
    profilePage: z.string(),
    contactName: z.string(),
    contactEmail: z.string(),
    contactFunction: z.string(),
    contactPhone: z.string(),
    inquiryUrl: z.string(),
    notification: z.string().optional(),
    dateOfEstablishment: z.string(),
    totalStudents: z.number(),
    yearOfRecognition: z.number(),
    nvaoDate: z.string(),
    partnerQuality: z.boolean(),
    partnerCount: z.array(TypeCountSchema),
    organizationChartUrl: z.string(),
    organizationChartType: z.enum(['application', 'image']).optional()
});

export type IPartnershipDetails = z.infer<typeof PartnershipDetailsSchema>;