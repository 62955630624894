import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "task-errors",
  class: "alert alert-danger w-100 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, errKey) => {
            return (_openBlock(), _createElementBlock("span", { key: errKey }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.taskComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.taskComponent), {
          key: 1,
          task: _ctx.task
        }, null, 8, ["task"]))
      : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 2 }))
  ], 64))
}