
import {defineComponent} from 'vue';
import PeerReviewEntry from '../../components/PeerReviewEntry.vue';
import ProgressSpinner from '../../components/standalone/ProgressSpinner.vue';
import CustomHeading from '../../components/CustomHeading.vue';
import usePeerReviews from '../../composeables/usePeerReviews';

export default defineComponent({
    name: 'PeerReviews',
    components: {CustomHeading, ProgressSpinner, PeerReviewEntry},
    setup() {
        return {
            peerReviews: usePeerReviews().reviews
        }
    }
})
