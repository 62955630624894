import {computed, ref} from 'vue';
import Api from '../features/Api';
import z from 'zod';

const educationSectors = ref<{[key: string]: string}>();
let loadingSectors = false;

export default () => {
    const refresh = () => {
        if (!loadingSectors) {
            loadingSectors = true;

            Api.get('get-education-sectors').then((response) => {
                const _data = (Array.isArray(response.data) && response.data.length === 0) ? {} : response.data;
                educationSectors.value = z.record(z.string()).parse(_data);
            }).finally(() => {
                loadingSectors = false;
            });
        }
    }

    // Auto-refresh if not yet defined
    if (!educationSectors.value) {
        refresh();
    }

    return {
        educationSectors: computed(() => educationSectors.value),
        refresh
    }
}