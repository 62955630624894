import {TaskSchema} from './Task';
import {UserSchema} from './User';
import {htmlDecode} from '../helpers/ZodHelper';
import {z} from 'zod';

export const ThreadSchema = z.object({
    id: z.number(),
    reviewId: z.number(),
    createdAt: z.string(),
    creator: z.union([UserSchema, z.undefined(), z.null()]),
    subject: z.string().transform(htmlDecode),
    unreadMessages: z.number(),
    partnershipName: z.string().transform(htmlDecode),
    recipientPartnershipName: z.string().transform(htmlDecode),
    sender: z.union([UserSchema, z.undefined(), z.null()]),
    task: z.union([TaskSchema, z.null()]).optional()
});

export type IThread = z.infer<typeof ThreadSchema>;