import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_2 = {
  key: 1,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.alerts === 'html' && _ctx.result)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.result.success && _ctx.successText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.successText), 1))
            : _createCommentVNode("", true),
          (!_ctx.result.success)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.errors, (errorMessage, key) => {
                  return (_openBlock(), _createElementBlock("span", { key: key }, _toDisplayString(errorMessage), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      ref: "form",
      method: "post",
      class: "simple-form",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 544)
  ]))
}