
import {defineComponent, PropType, ref} from 'vue';
import SimpleForm from '@/form/SimpleForm.vue';
import {ISimpleResponse} from '../../../form/SimpleForm.vue';
import {IPanelMember, PanelMemberSchema} from '../../../models/PanelMember';

export default defineComponent({
    name: 'PanelMemberInput',
    components: {SimpleForm},
    props: {
        reviewId: {type: Number, required: true},
        panelMember: {type: Object as PropType<IPanelMember>, required: true},
        disabled: {type: Boolean, default: false}
    },
    setup(props) {
        const isBusy = ref(false);
        const action = ref<'set'|'unset'>('set');
        const member = ref(Object.seal({
            name: props.panelMember.user?.name,
            email: props.panelMember.user?.email,
            locked: Boolean(props.panelMember.user?.email)
        }));

        return {
            action,
            isBusy,
            member,
            onSubmit() {
                isBusy.value = true;
            },
            onResponse(response: ISimpleResponse) {
                if (response.success) {
                    const panelMember = PanelMemberSchema.parse(response.data);

                    member.value.name = panelMember.user?.name ?? undefined;
                    member.value.email = panelMember.user?.email ?? undefined;
                    member.value.locked = Boolean(panelMember.user?.email);
                } else {
                    window.alert(response.errors.join(' '));
                }

                isBusy.value = false;
            },
            addPanelMember() {
                action.value = 'set';
            },
            removePanelMember(e: SubmitEvent) {
                const confirm = window.confirm('Weet u zeker dat u dit panellid wil verwijderen?');
                if (confirm) {
                    action.value = 'unset';
                } else {
                    e.preventDefault();
                }
            }
        }
    }
})
