
import {defineComponent, ref} from 'vue';
import useDataEditor from '../../composeables/useDataEditor';
import DataForm from '@/form/DataForm.vue';
import useReviewData from '../../composeables/useReviewData';
import DynamicFormField from '@/form/DynamicFormField.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'DataEditorPage',
    components: {DynamicFormField, DataForm, ProgressSpinner},
    setup() {
        const isLoading = ref(true);
        const reviewData = useReviewData().reviewData.value;
        if (!reviewData) {
            throw 'No review has been loaded.';
        }

        const keys = [useDataEditor().valueToEdit.value];
        const allKeysLoaded = keys.every((key) => Object.hasOwn(reviewData, key));

        if (allKeysLoaded) {
            isLoading.value = false;
        } else {
            useReviewData().load(reviewData.id, keys).then(() => {
                isLoading.value = false;
            });
        }

        return {
            data: useReviewData().reviewData,
            dataKey: useDataEditor().valueToEdit,
            component: useDataEditor().fieldComponent,
            isLoading
        }
    }
})
