type FormDataValue = string|Blob|boolean|number|bigint|symbol|{[p: string]: FormDataValue};

export default class FormDataHelper {
    public static objectToFormData = (data: {[p: string]: FormDataValue}|FormData) => {
        if (data instanceof FormData) {
            return data;
        }

        const formData = new FormData();
        for (const metaKey in data) {
            const dataValue = data[metaKey];
            this.setFormData(formData, metaKey, dataValue);
        }

        return formData;
    }

    private static setFormData(formData: FormData, metaKey: string, dataValue: FormDataValue) {
        if (typeof dataValue === 'string' || dataValue instanceof Blob) {
            formData.set(metaKey, dataValue);
        } else if (typeof dataValue === 'boolean' || typeof dataValue === 'number' || typeof dataValue === 'bigint' || typeof dataValue === 'symbol') {
            formData.set(metaKey, String(dataValue));
        } else if (typeof dataValue === 'object') {
            for (const dataKey in dataValue) {
                this.setFormData(formData, `${metaKey}[]`, dataValue[dataKey]);
            }
        }
    }
}