
import {defineComponent} from 'vue';
import usePartnershipInvites, {PartnershipSelectionType} from '../../../composeables/usePartnershipInvites';
import PartnershipCard from '../../PartnershipCard.vue';

export default defineComponent({
    name: 'ShortlistPeerReviewVisitorSelect',
    components: {PartnershipCard},
    setup() {
        return {
            partnershipType: PartnershipSelectionType.SHORTLIST,
            shortlist: usePartnershipInvites().shortlist
        }
    }
})
