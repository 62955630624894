import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "heading" }
const _hoisted_2 = { class: "ml-auto ml-lg-0 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrevPageButton = _resolveComponent("PrevPageButton")!
  const _component_CurrentUserAvatar = _resolveComponent("CurrentUserAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PrevPageButton),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_CurrentUserAvatar, {
      "icon-only": true,
      class: "ml-auto"
    })
  ]))
}