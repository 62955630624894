
import {defineComponent, PropType} from 'vue';
import {IReviewData} from '../../models/ReviewData';

export default defineComponent({
    name: 'SetDatePickerUrlField',
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    }
})
