import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "avatar__image" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user?.avatarUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.user.avatarUrl
        }, null, 8, _hoisted_2))
      : (_ctx.user)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.user.name[0]), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createTextVNode("?")
          ], 64))
  ]))
}