
import Vue3Html2pdf from 'vue3-html2pdf';
import {defineComponent, PropType, ref} from 'vue';
import {IDetailedPartnership} from '../features/interfaces';
import PartnershipIdentity from '@/components/PartnershipIdentity.vue';

export default defineComponent({
    name: 'PartnershipIdentityDownload',
    components: {PartnershipIdentity, Vue3Html2pdf},
    props: {
        partnership: {type: Object as PropType<IDetailedPartnership>, required: true}
    },
    setup() {
        const html2Pdf = ref();

        return {
            download() {
                html2Pdf.value?.generatePdf();
            },
            html2Pdf
        }
    }
})
