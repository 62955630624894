
import {defineComponent, PropType, ref} from 'vue';
import {IReviewData} from '../../models/ReviewData';

export default defineComponent({
    name: 'SelectPreliminaryDiscussionDatetimeAndLocationField',
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    setup(props) {
        const discussionDate = ref(props.data.preliminary_discussion?.date || '');
        const discussionStartTime = ref(props.data.preliminary_discussion?.startTime || '');
        const discussionEndTime = ref(props.data.preliminary_discussion?.endTime || '');
        const locationType = ref(props.data.preliminary_discussion?.locationType || '');
        const locationDetails = ref(props.data.preliminary_discussion?.locationDetails || '');

        return {
            locationType,
            discussionDate,
            discussionStartTime,
            discussionEndTime,
            locationDetails
        }
    }
})
