
import {defineComponent, ref} from 'vue';
import useActiveTask from '../composeables/useActiveTask';
import TaskCompleteMessage from '@/components/tasks/TaskCompleteMessage.vue';

export default defineComponent({
    name: 'TaskForm',
    components: {TaskCompleteMessage},
    emits: ['submit'],
    setup(props, context) {
        const form = ref<HTMLFormElement>();
        return {
            task: useActiveTask().activeTask,
            emitSubmit() {
                context.emit('submit', form.value);
            },
            emit: context.emit,
            form
        };
    }
});
