
import {defineComponent, PropType} from 'vue';
import {IDetailedPartnership} from '../../features/interfaces';

export default defineComponent({
    name: 'VisitingPartnership',
    props: {
        index: {type: Number, required: true},
        partnership: {type: Object as PropType<IDetailedPartnership>, default: undefined},
        inviteStatus: {type: String as PropType<'accepted'|'pending'>, default: undefined}
    }
})
