import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { value: "0" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: "form-control",
    disabled: !_ctx.reviews || _ctx.reviews.length <= 1,
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
  }, [
    _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.labelPrefix) + " alle reviews", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.reviews ?? []), (review, key) => {
      return (_openBlock(), _createElementBlock("option", {
        key: key,
        value: review.id
      }, _toDisplayString(review.title), 9, _hoisted_3))
    }), 128))
  ], 40, _hoisted_1))
}