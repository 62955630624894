
import {defineComponent} from 'vue';
import DynamicTaskDisplay from '../../components/tasks/DynamicTaskDisplay.vue';
import useActiveTask from '../../composeables/useActiveTask';

export default defineComponent({
    name: 'TaskSingle',
    components: {DynamicTaskDisplay},
    props: {
        id: {type: Number, required: true},
        reviewId: {type: Number, required: true}
    },
    setup(props) {
        useActiveTask().closeTask();
        useActiveTask().openTask(props.id);

        return {
            task: useActiveTask().activeTask
        }
    }
})
