
import {defineComponent, PropType, ref} from 'vue';

export interface ISimpleResponse {
    errors: string[];
    success: boolean;
    data?: unknown;
}

export default defineComponent({
    name: 'SimpleForm',
    props: {
        target: {type: String, required: true},
        alerts: {type: String as PropType<'html'|'window'|'off'>, default: 'html'},
        successText: {type: String, default: 'Uw gegevens zijn geüpdatet. Dank voor het invullen.'}
    },
    emits: ['response', 'submit'],
    setup(props, context) {
        const form = ref<HTMLFormElement>();
        const result = ref<ISimpleResponse>();

        return {
            form,
            result,
            submit() {
                const data = new FormData(form.value);
                context.emit('submit', data);

                fetch(`${window.location.origin}/${props.target}/`, {method: 'POST', body: data}).then((jsonResponse) => {
                    jsonResponse.json().then((response: ISimpleResponse) => {
                        result.value = response;
                        context.emit('response', response, form.value);

                        if (props.alerts === 'window' && response) {
                            if (result.value?.success) {
                                window.alert(props.successText);
                            } else {
                                window.alert(response.errors.join('\r\n'));
                            }
                        }
                    });
                });
            }
        };
    }
})
