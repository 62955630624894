
import {defineComponent, PropType} from 'vue';
import DocumentUploadField from '@/form/DocumentUploadField.vue';
import {IReviewData} from '../../models/ReviewData';

export default defineComponent({
    name: 'UploadReviewReportFeedbackField',
    components: {DocumentUploadField},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    }
})
