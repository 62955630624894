import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["viewBox", "height", "width"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: _ctx.viewbox,
    height: `${_ctx.size}px`,
    width: `${_ctx.size}px`,
    xmlns: "http://www.w3.org/2000/svg",
    class: _normalizeClass(`svg-icon-${_ctx.icon}`)
  }, [
    _createElementVNode("path", { d: _ctx.path }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}