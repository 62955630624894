import {computed, ref} from 'vue';
import Api from '../features/Api';
import {ITimeslot, TimeslotSchema} from '../models/Timeslot';
import z from 'zod';

const timeslots = ref<ITimeslot[]>();
let loading: Promise<void>|undefined;

export default () => {
    return {
        load(reviewId: number) {
            if (!loading) {
                loading = Api.get(`get-date-brackets/${reviewId}`).then((response) => {
                    timeslots.value = z.array(TimeslotSchema).parse(response.data);
                    loading = undefined;
                });
            }

            return loading;
        },
        timeslots: computed(() => timeslots.value)
    }
}