
import {defineComponent, PropType, ref} from 'vue';
import {IReviewData} from '../../models/ReviewData';
import DocumentMultiUploadField from '@/form/DocumentMultiUploadField.vue';

export default defineComponent({
    name: 'UploadReviewDayDocumentsField',
    components: {DocumentMultiUploadField},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    setup(props) {
        const reviewDayStartTime = ref(props.data.peer_review_day_program?.startTime || '');
        const reviewDayEndTime = ref(props.data.peer_review_day_program?.endTime || '');
        const reviewDayLocation = ref(props.data.peer_review_day_program?.locationDetails || '');

        return {
            reviewDayStartTime,
            reviewDayEndTime,
            reviewDayLocation
        }
    }
})
