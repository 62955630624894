
import {defineComponent, PropType} from 'vue';
import useActiveTask from '../../composeables/useActiveTask';
import SelectVisitingPartnershipsTask from './selectVisitingPartnershipsTask/SelectVisitingPartnershipsTask.vue';
import AcceptInviteTask from './AcceptInviteTask.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import DataBoundTask from '@/components/DataBoundTask.vue';
import CheckPartnershipDataTask from '@/components/tasks/CheckPartnershipDataTask.vue';
import {IFullTask} from '../../models/FullTask';

export default defineComponent({
    name: 'DynamicTaskDisplay',
    components: {ProgressSpinner, AcceptInviteTask, SelectVisitingPartnershipsTask, DataBoundTask, CheckPartnershipDataTask},
    props: {
        task: {type: Object as PropType<IFullTask>, required: true}
    },
    setup(props) {
        return {
            taskComponent: props.task?.taskComponent,
            errors: useActiveTask().errors
        }
    }
})
