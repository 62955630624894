
import {defineComponent, ref} from 'vue';
import SimpleForm, {ISimpleResponse} from '../../form/SimpleForm.vue';
import useCurrentUser from '../../composeables/useCurrentUser';
import ImageUploadField from '@/form/ImageUploadField.vue';
import {UserSchema} from '../../models/User';

export default defineComponent({
    name: 'ProfileEdit',
    components: {ImageUploadField, SimpleForm},
    setup() {
        const disabled = ref(false);

        return {
            user: useCurrentUser().user,
            disabled,
            onSubmit: () => {
                disabled.value = true;
            },
            onResponse: (response: ISimpleResponse) => {
                useCurrentUser().set(UserSchema.parse(response.data));
                disabled.value = false;
            }
        }
    }
})
