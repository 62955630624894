
import {defineComponent, PropType} from 'vue';
import DocumentUploadField from '@/form/DocumentUploadField.vue';
import {IReviewData} from '../../models/ReviewData';
import DocumentMultiUploadField from '@/form/DocumentMultiUploadField.vue';

export default defineComponent({
    name: 'UploadCriticalReflectionField',
    components: {DocumentMultiUploadField, DocumentUploadField},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    }
})
