
import {defineComponent} from 'vue';
import usePartnership from '../../composeables/usePartnership';
import PartnershipIdentity from '../../components/PartnershipIdentity.vue';

export default defineComponent({
    name: 'PartnershipIdentityPage',
    components: {PartnershipIdentity},
    props: {
        id: {type: Number, required: true}
    },
    setup(props) {
        usePartnership().openPartnership(props.id);

        return {
            partnership: usePartnership().activePartnership
        };
    }
})
