
import {defineComponent, PropType} from 'vue';
import {IUser} from '../models/User';

export default defineComponent({
    name: 'UserAvatar',
    props: {
        user: {type: Object as PropType<IUser|undefined>, default: undefined}
    }
})
