
import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'LimitedTextArea',
    props: {
        name: {type: String, required: true},
        disabled: {type: Boolean, default: false},
        value: {type: String, default: ''},
        limit: {type: Number, default: 300}
    },
    setup(props) {
        const text = ref('');

        return {
            text,
            onInput(e: InputEvent & {target: HTMLTextAreaElement}) {
                if (e.target.value.length > props.limit) {
                    e.target.value = e.target.value.slice(0, props.limit);
                }

                text.value = e.target.value;
            }
        }
    }
})
