
import {defineComponent, PropType} from 'vue';
import {IUser} from '../models/User';
import UserAvatar from '@/components/UserAvatar.vue';

export default defineComponent({
    name: 'PeerAvatar',
    components: {UserAvatar},
    props: {
        user: {type: Object as PropType<IUser|undefined>, default: undefined},
        showName: {type: Boolean, default: true},
        showButton: {type: Boolean, default: true}
    }
})
