import {computed, ref} from 'vue';
import Api from '../features/Api';
import {IUser, UserSchema} from '../models/User';

const user = ref<IUser>();
let loadingUser: Promise<IUser>|undefined;

export default () => {
    const load = () => {
        if (!loadingUser) {
            loadingUser = Api.get('get-current-user').then((response) => {
                if (!response.data) {
                    throw 'User session expired';
                }

                const newUser = UserSchema.parse(response.data);
                user.value = newUser;
                loadingUser = undefined;

                return newUser;
            });
        }

        return loadingUser;
    };

    // Initial api call
    if (!user.value) {
        load();
    }

    return {
        getRef() {
          return computed(() => user)
        },

        set(_user: IUser) {
            user.value = _user;
        },

        async getStatic() {
            let data = user.value;

            if (data === undefined) {
                data = await load();
            }

            return data;
        },

        user: computed(() => user.value),

        load: load
    }
}