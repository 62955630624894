
import {defineComponent} from 'vue';
import PrevPageButton from './PrevPageButton.vue';
import CurrentUserAvatar from './CurrentUserAvatar.vue';

export default defineComponent({
    name: 'CustomHeading',
    components: {CurrentUserAvatar, PrevPageButton},
    props: {
        title: {type: String, required: true},
    }
})
