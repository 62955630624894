import {ref} from 'vue';

export default class ApiResource<T> {
    private promise: Promise<T> | undefined;
    private readonly data = ref<T>();
    private readonly target: string;
    private readonly formatter: (data: unknown) => T;

    constructor(target: string, formatter: (data: unknown) => T) {
        this.target = target;
        this.formatter = formatter;
    }

    public get() {
        if (this.data.value === undefined) {
            this.load();
        }

        return this.data;
    }

    public load(forceRefresh = false) {
        if (!forceRefresh && this.data.value !== undefined) {
            return Promise.resolve(this.data.value);
        }

        if (!this.promise) {
            this.promise = fetch(`${window.location.origin}/${this.target}`).then((response) => response.json()).then((data) => {
                this.promise = undefined;
                this.data.value = this.formatter(data);

                return this.data.value;
            });
        }

        return this.promise;
    }

    public clear() {
        this.promise = undefined;
        this.data.value = undefined;
    }
}
