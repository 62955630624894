import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-view__item" }
const _hoisted_2 = { class: "card card--task" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title mb-0" }
const _hoisted_5 = {
  key: 1,
  class: "task-completed"
}
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseBadge = _resolveComponent("BaseBadge")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.task.title), 1),
        (!_ctx.task.isComplete)
          ? (_openBlock(), _createBlock(_component_BaseBadge, {
              key: 0,
              value: 1,
              type: "warning"
            }))
          : _createCommentVNode("", true),
        (_ctx.task.isComplete)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_icon, {
                icon: "check",
                fill: "currentColor"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, "Status: " + _toDisplayString(_ctx.task.isComplete ? 'Uitgevoerd' : 'Nog uit te voeren'), 1),
        (!_ctx.task.isComplete)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-secondary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toTask && _ctx.toTask(...args)))
            }, "Taak uitvoeren"))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}