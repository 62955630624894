import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import {trackRouter} from 'vue-gtag-next';
import PeerReview from '../views/overviews/PeerReviews.vue';
import Stappenplan from '../views/Stappenplan.vue';
import PeerReviewSingle from '../views/single/PeerReviewSingle.vue';
import Inbox from '../views/overviews/Inbox.vue';
import InboxSingle from '../views/single/InboxSingle.vue';
import Tasks from '../views/overviews/Tasks.vue';
import TaskSingle from '../views/single/TaskSingle.vue';
import {RouteLocationNormalized} from 'vue-router';
import ProfileEdit from '@/views/profile/ProfileEdit.vue';
import PartnershipIdentityPage from '@/views/pages/PartnershipIdentityPage.vue';

const RouteNames = [
    'ProfileEdit',
    'PeerReviews',
    'PeerReviewSingle',
    'Inbox',
    'InboxSingle',
    'Tasks',
    'TaskSingle',
    'Stappenplan',
    'Partnerschap'
] as const;

type RouteRecordRawWithName = RouteRecordRaw & {
    name?: typeof RouteNames[number];
}

const toInt = (input: string|string[]) => {
    if (Array.isArray(input)) {
        return input.map((item) => Number(item))
    }

    return Number(input);
}

const asNumbers = (loc: RouteLocationNormalized) => {
    const obj: Record<string, number|number[]> = {};

    for (const paramsKey in loc.params) {
        obj[paramsKey] = toInt(loc.params[paramsKey]);
    }

    return obj;
}

const _routes: RouteRecordRawWithName[] = [
    {path: '/', name: 'PeerReviews', component: PeerReview},
    {path: '/partnerschap/:id/', name: 'Partnerschap', component: PartnershipIdentityPage, props: asNumbers},
    {path: '/stappenplan/', name: 'Stappenplan', component: Stappenplan},
    {path: '/profile/edit/', name: 'ProfileEdit', component: ProfileEdit},
    {path: '/peerreviews', redirect: {name: 'PeerReviews'}},
    {path: '/peerreviews/:id/', name: 'PeerReviewSingle', component: PeerReviewSingle, props: asNumbers},
    {path: '/inbox/', name: 'Inbox', component: Inbox, children: [
        {path: '/inbox/message/:reviewId/:id/', name: 'InboxSingle', component: InboxSingle, props: asNumbers}
    ]},
    {path: '/tasks/', name: 'Tasks', component: Tasks, children: [
        {path: '/tasks/task/:reviewId/:id/', name: 'TasksSingle', component: TaskSingle, props: asNumbers}
    ]}
];

const router = createRouter({history: createWebHashHistory(), routes: _routes});

trackRouter(router);

export default router;