
import {computed, defineComponent, PropType} from 'vue';
import usePartnership from '../composeables/usePartnership';
import useModal from '../composeables/useModal';
import usePartnershipInvites, {PartnershipSelectionType} from '../composeables/usePartnershipInvites';
import PartnershipInviteStatusBadge from './tasks/selectVisitingPartnershipsTask/PartnershipInviteStatusBadge.vue';
import {IPartnershipChoice} from '../models/Partnership';
import useActiveTask from '../composeables/useActiveTask';

export default defineComponent({
    name: 'PartnershipCard',
    components: {PartnershipInviteStatusBadge},
    props: {
        partnership: {type: Object as PropType<IPartnershipChoice>, required: true},
        inviteStatus: {type: String, default: undefined},
        selectableAs: {type: Number as PropType<PartnershipSelectionType>, default: undefined}
    },
    setup(props) {
        return {
            requestDetails() {
                usePartnership().openPartnership(props.partnership.id);
                useModal().open('PartnershipDetail');
            },
            onSelect() {
                if (props.partnership && props.selectableAs) {
                    usePartnershipInvites().selectPartnership(props.selectableAs, props.partnership);
                    useModal().close();
                } else {
                    throw 'You are not supposed to select this partnership.';
                }
            },
            onRequestNewEntry() {
                usePartnershipInvites().trashShortlistEntry(useActiveTask().reviewId.value, props.partnership.id);
            },
            unavailableReason: computed(() => props.selectableAs === PartnershipSelectionType.FREE ? props.partnership.unavailableAsFreeChoiceReason : props.partnership.unavailableAsShortlistChoiceReason),
            PartnershipSelectionType
        }
    }
})
