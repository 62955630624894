import {computed, ref} from 'vue';
import Api from '../features/Api';
import {z} from 'zod';

const PanelRoleLabelsSchema = z.object({
    programLeader: z.string(),
    schoolRepresentative : z.string(),
    instituteTrainer: z.string(),
    schoolTrainer: z.string(),
    workplaceSupervisor: z.string(),
    student: z.string()
});

export type PanelRoleLabels = z.infer<typeof PanelRoleLabelsSchema>;

const panelMembers = ref<PanelRoleLabels>();

export default () => {
    return {
        get() {
            if (!panelMembers.value) {
                Api.get(`role-labels`).then((response) => {
                    panelMembers.value = PanelRoleLabelsSchema.parse(response.data);
                });
            }

            return computed(() => Object.freeze(panelMembers.value));
        }
    }
}