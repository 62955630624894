import {computed, ref} from 'vue';
import Api from '../features/Api';
import {FullTaskSchema, IFullTask} from '../models/FullTask';
import useTasks from '../composeables/useTasks';
import useReviewData from '../composeables/useReviewData';
import {ReviewDataSchema} from '../models/ReviewData';
import z from 'zod';

const activeTask = ref<Readonly<IFullTask>>();
const errors = ref<string[]>([]);
let taskPromise: Promise<void>|undefined;
let autosaveTimeout: number|undefined = undefined;

const TaskResponseSchema = z.union([
    // Success Response (Might not contain 'errors' array)
    z.object({
        success: z.literal(true),
        errors: z.tuple([]).optional().default([]),
        reviewData: ReviewDataSchema.nullish(),
        task: z.record(z.unknown())
    }),
    // Failure Response (Might not contain 'task' object
    z.object({
        success: z.literal(false),
        errors: z.array(z.string()),
        reviewData: ReviewDataSchema.nullish(),
        task: z.record(z.unknown()).optional()
    })
]);

export default () => {
    function setTask(taskData: unknown) {
        const task = (taskData) ? Object.freeze(FullTaskSchema.parse(taskData)) : undefined;
        activeTask.value = task;
        if (task) {
            useTasks().setTask(task);
        }
    }

    function setErrors(errorData: string[]) {
        errors.value = errorData;
    }

    return {
        isLoading: computed(() => Boolean(taskPromise)),
        activeTask: computed(() => activeTask.value),
        errors: computed(() => errors.value ?? []),
        reviewId: computed(() => {
            if (!activeTask.value?.peerReviewId) {
                throw 'No active task with a Peer Review ID is currently set!'
            }

            return activeTask.value.peerReviewId;
        }),

        getPromise() {
          return taskPromise ?? Promise.resolve();
        },

        openTask(taskId: number) {
            if (!taskPromise) {
                activeTask.value = undefined;

                return Api.get(`get-user-task/${taskId}`).then((response) => {
                    setTask(response.data);
                    setErrors([]);
                    taskPromise = undefined;
                });
            }

            return taskPromise;
        },

        closeTask() {
            activeTask.value = undefined;
        },

        autosaveTask(data: FormData) {
            clearTimeout(autosaveTimeout);

            autosaveTimeout = setTimeout(() => {
                if (activeTask.value) {
                    data.set('taskId', activeTask.value.id.toString());
                    data.set('reviewId', activeTask.value.peerReviewId.toString());

                    Api.post('autosave-task-form', data, activeTask.value?._nonce);
                }
            }, 250);
        },

        submitTask(formData = new FormData()) {
            if (activeTask.value) {
                setErrors([]);

                formData.set('taskId', activeTask.value.id.toString());

                return Api.post('submit-task-form', formData, activeTask.value._nonce).then((response) => {
                    const taskResponseData= TaskResponseSchema.parse(response.data);

                    setErrors(taskResponseData.errors);

                    if (taskResponseData.task) {
                        setTask(taskResponseData.task);
                    }

                    if (taskResponseData.reviewData) {
                        useReviewData().updateReviewData(taskResponseData.reviewData)
                    }

                    if (taskResponseData.success) {
                        useTasks().refresh();
                    } else {
                        setTimeout(() => {
                            document.getElementById('task-errors')?.scrollIntoView();
                        }, 10);
                    }

                    return Boolean(taskResponseData.success);
                });
            }

            throw 'No active task!';
        }
    }
}