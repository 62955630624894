
import {defineComponent, PropType} from 'vue';
import FetchHelper from '../helpers/FetchHelper';
import useActiveThread from '../composeables/useActiveThread';
import {IMessage} from '../models/Message';

export default defineComponent({
    name: 'ThreadMessage',
    props: {
        message: {type: Object as PropType<IMessage>, required: true},
        isFirst: {type: Boolean, default: false}
    },
    setup(props) {
        if (props.message.status === 'unread') {
            const reviewId = useActiveThread().activeThread.value?.reviewId;
            if (reviewId) {
                FetchHelper.get(`wp-json/api/mark-as-read/${props.message.id}/`);
            }
        }
    }
})
