import {IFullTask} from '../models/FullTask';
import z from 'zod';
import {htmlDecode} from '../helpers/ZodHelper';

export const ContentPageSchema = z.object({
    content: z.string()
});

export const TypeCountSchema = z.object({
    label: z.string(),
    amount: z.number()
});

export const MinimalPartnershipSchema = z.object({
    id: z.number(),
    label: z.string().transform(htmlDecode),
    city: z.string(),
    educationSector: z.string()
});

export const DetailedPartnershipSchema = MinimalPartnershipSchema.extend({
    profilePage: z.string(),
    logo: z.string(),
    address: z.string(),
    zipCode: z.string(),
    inquiryUrl: z.string(),
    yearOfRecognition: z.number(),
    dateOfEstablishment: z.string(),
    totalStudents: z.number(),
    partnerCount: z.array(z.object({label: z.string(), amount: z.number()})),
    nvaoDate: z.string(),
    partnerQuality: z.boolean(),
    organizationChartUrl: z.string(),
    organizationChartType: z.enum(['application', 'image']).optional()
});

// These are all API values, which is why they are readonly
export type IContentPage = z.infer<typeof ContentPageSchema>;

export type ITypeCount = z.infer<typeof TypeCountSchema>;

export interface ITaskFormResponse {
    readonly task: IFullTask;
    readonly errors: string[];
    readonly success: boolean;
}

export type IDetailedPartnership = z.infer<typeof DetailedPartnershipSchema>;
