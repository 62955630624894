
import {computed, defineComponent} from 'vue';
import VisitingPartnership from '@/components/peerreview/VisitingPartnership.vue';
import BsCard from '@/components/bootstrap/BsCard.vue';
import BsDocumentCard from '@/components/bootstrap/BsDocumentCard.vue';
import CoordinatorList from '@/components/peerreview/CoordinatorList.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import useReviewData from '../../composeables/useReviewData';
import EditReviewDataButton from '@/form/EditReviewDataButton.vue';
import PanelMemberUserInfo from '@/components/peerreview/PanelMemberUserInfo.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import {IPanelMember} from '../../models/PanelMember';
import useCurrentUser from '../../composeables/useCurrentUser';
import PartnershipIdentityDownload from '@/components/PartnershipIdentityDownload.vue';

export default defineComponent({
    name: 'PeerreviewSingle',
    components: {PartnershipIdentityDownload, ProgressSpinner, EditReviewDataButton, UserAvatar, BsCard, VisitingPartnership, CoordinatorList, PanelMemberUserInfo, BsDocumentCard},
    props: {
        id: {type: Number, default: 0}
    },
    setup(props) {
        useReviewData().load(props.id);
        const reviewData = useReviewData().reviewData;

        return {
            user: useCurrentUser().user,
            reviewData,
            showFirstVisitors: computed(() => reviewData.value?.visiting_partnerships?.first && reviewData.value?.visiting_partnerships?.firstStatus === 'accepted'),
            showSecondVisitors: computed(() => reviewData.value?.visiting_partnerships?.second && reviewData.value?.visiting_partnerships?.secondStatus === 'accepted'),
            panelUsers: computed(() => {
                const obj: Record<string, IPanelMember> = {};
                const r = useReviewData().reviewData.value;
                const users = [...(r?.panel_members_1 || []), ...(r?.panel_members_2 || [])];

                for (const user of users) {
                    obj[user.panelRole] = user;
                }

                return obj;
            }),
            panelRoles: computed(() => {
                const output: {first: string[]; second: string[]} = {first: [], second: []};
                const roles = useReviewData().reviewData.value?.panel_member_roles || {};

                for (const key in roles) {
                    if (roles[key] === 1) {
                        output.first.push(key);
                    } else if (roles[key] === 2) {
                        output.second.push(key);
                    }
                }

                return output;
            })
        }
    }
})
