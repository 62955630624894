import {computed, ref} from 'vue';
import Api from '../features/Api';
import {IPartnershipDetails, PartnershipDetailsSchema} from '../models/PartnershipDetails';

const activePartnership = ref<IPartnershipDetails>();

export default () => {
    const refresh = () => {
        if (activePartnership.value) {
            openPartnership(activePartnership.value.id);
        }
    }

    const openPartnership = (partnerId: number) => {
        closePartnership();
        Api.get(`get-partnership/${partnerId}`).then((response) => {
            activePartnership.value = (response.data) ? PartnershipDetailsSchema.parse(response.data) : undefined;
        });
    }

    const closePartnership = () => {
        activePartnership.value = undefined;
    }

    return {
        activePartnership: computed(() => activePartnership.value),
        refresh,
        openPartnership,
        closePartnership
    }
}