import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskForm = _resolveComponent("TaskForm")!

  return (_openBlock(), _createBlock(_component_TaskForm, { onSubmit: _ctx.onSubmit }, {
    default: _withCtx(() => [
      _createElementVNode("a", {
        class: "btn btn-secondary",
        href: _ctx.inquiryUrl,
        target: "_blank"
      }, " Klik hier om de gegevens van uw partnerschap te controleren ", 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}