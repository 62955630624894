/**
 * Attempts to decode HTML in a given string.<br>
 * If this operation fails, an empty string will be returned.
 */
export function htmlDecode(input: string) {
    return (new DOMParser().parseFromString(input, 'text/html')).documentElement.textContent || '';
}

export function restoreCharacters(input: string) {
    return input.replace('&#8211;', '—');
}