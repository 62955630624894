
import {defineComponent, PropType, ref} from 'vue';
import {IVisitingCoordinator} from '../../../models/User';
import Api from '../../../features/Api';
import useRoleLabels from '../../../composeables/useRoleLabels';

export default defineComponent({
    name: 'ProgramLeaderInput',
    props: {
        disabled: {type: Boolean, default: false},
        reviewId: {type: Number, required: true},
        coordinators: {type: Object as PropType<IVisitingCoordinator[]>, required: true},
        canSelectNobody: {type: Boolean, required: true}
    },
    setup(props) {
        const programLeader = ref<HTMLSelectElement>();

        return {
            programLeader,
            roleLabels: useRoleLabels().get(),
            updateProgramLeader() {
                Api.post(`set-leader/${props.reviewId}/${programLeader.value?.value || 0}`, {});
            }
        }
    }
})
