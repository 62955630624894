import {PartnershipChoiceSchema} from './Partnership';
import z from 'zod';

export const InviteRequestSchema = z.object({
    id: z.number(),
    invitedPartnership: PartnershipChoiceSchema,
    sendingPartnership: PartnershipChoiceSchema,
    peerReviewId: z.number(),
    isRetractable: z.boolean(),
    status: z.enum(['draft', 'pending', 'accepted', 'rejected']),
    comment: z.string(),
    responseComment: z.string(),
    isAcceptable: z.boolean(),
    reasonForBeingUnacceptable: z.string(),
    reviewDate: z.string(),
    slot: z.number(),
    trashed: z.boolean()
});

export type IInviteRequest = z.infer<typeof InviteRequestSchema>;