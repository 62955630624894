
import {defineComponent, PropType} from 'vue';
import {IFullTask} from '../../models/FullTask';

export default defineComponent({
    name: 'TaskCompleteMessage',
    props: {
        task: {type: Object as PropType<IFullTask>, required: true}
    }
})
