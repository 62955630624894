import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "avatar__content"
}
const _hoisted_2 = { class: "avatar__name" }
const _hoisted_3 = {
  key: 0,
  class: "avatar__function"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: {name: 'ProfileEdit'},
    class: "avatar"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UserAvatar, {
        user: _ctx.user || undefined
      }, null, 8, ["user"]),
      (_ctx.showName && _ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.user.name), 1),
            (_ctx.user.duty)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.user.duty), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}