
import {defineComponent, PropType} from 'vue';
import PeerAvatar from './PeerAvatar.vue';
import BaseBadge from './badges/BaseBadge.vue';
import {IThread} from '../models/Thread';

export default defineComponent({
    name: 'InboxEntry',
    components: {BaseBadge, PeerAvatar},
    props: {
        thread: {type: Object as PropType<IThread>, required: true}
    }
})
