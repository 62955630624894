import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "badge badge-primary" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "badge badge-danger" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "badge badge-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.inviteStatus === 'pending')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.partnership.label) + " heeft nog niet gereageerd op de uitnodiging. ", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.inviteStatus === 'rejected')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.partnership.label) + " heeft de uitnodiging afgewezen. ", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.inviteStatus === 'accepted')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.partnership.label) + " heeft de uitnodiging geaccepteerd. ", 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}