
import {defineComponent} from 'vue';
import useModal from '../../composeables/useModal';
import PartnershipDetail from '../PartnershipDetail.vue';
import FreePeerReviewVisitorSelect from '../tasks/selectVisitingPartnershipsTask/FreePeerReviewVisitorSelect.vue';
import DataEditorPage from '@/views/pages/DataEditorPage.vue';

export default defineComponent({
    name: 'GlobalModal',
    components: {FreePeerReviewVisitorSelect, PartnershipDetail, DataEditorPage},
    setup() {
        return {
            modalComponent: useModal().componentName,
            modalStack: useModal().componentStack,
            canGoBack: useModal().canGoBack,
            goBack: useModal().goBack,
            closeModal: useModal().close
        };
    }
})
