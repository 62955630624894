
import {defineComponent} from 'vue';
import usePeerReviews from '../../composeables/usePeerReviews';

export default defineComponent({
    name: 'ReviewFilter',
    props: {
        labelPrefix: {type: String, default: ''}
    },
    emits: ['change'],
    setup(props, context) {
        return {
            reviews: usePeerReviews().combinedReviews,
            onChange(e: InputEvent & {target: HTMLSelectElement}) {
                context.emit('change', parseInt(e.target.value, 10));
            }
        }
    }
})
