import FetchHelper from '../helpers/FetchHelper';
import {computed, ref} from 'vue';
import {IReviewData, ReviewDataKey, ReviewDataSchema} from '../models/ReviewData';
import FormDataHelper from '../helpers/FormDataHelper';

const reviewData = ref<Readonly<IReviewData>>();

interface IResponse {
    success: boolean;
    errors: string[];
    output: {[p: string]: unknown};
}

function updateReviewData(newData: Partial<IReviewData>) {
    const fields = (reviewData.value?.fields) ? {...reviewData.value.fields, ...newData.fields} : newData.fields;
    const currentData = reviewData.value || {};
    const updatedData = Object.freeze(ReviewDataSchema.parse({...currentData, ...newData, fields}));

    reviewData.value = updatedData;

    return updatedData;
}

export default () => ({
    reviewData: computed(() => reviewData.value),
    updateReviewData,
    set(dataToSet: FormData) {
        const review = reviewData.value;
        if (!review) {
            throw 'Cannot set data as no review has been loaded yet.';
        }

        const formData = FormDataHelper.objectToFormData(dataToSet);
        return FetchHelper.post<IResponse>(`wp-json/api/set-review-data/${review.id}/`, formData).then((response) => {
            if (response?.output) {
                updateReviewData(response.output);
            }

            return response;
        });
    },
    /** Returns a non-reactive copy of the viewData object. Throws an exception if reviewData is not set. */
    get() {
        const value = reviewData.value;
        if (!value) {
            throw 'Cannot "get" review data object because it is undefined.';
        }

        return value;
    },
    /**
     * Load review data.
     * @param reviewId The review ID. Required.
     * @param keys Excepts an array of the data you wish to load. If provided, only loads keys that have not been loaded already. If empty, loads everything.
     */
    load(reviewId: number, keys?: ReviewDataKey[]) {
        // If we are switching to a different review, unset all data on the current review
        if (reviewData.value?.id !== reviewId) {
            reviewData.value = undefined;
        }

        // Check what keys we already have on our active review
        const formData = new FormData();
        const activeKeys = ((reviewData.value) ? Object.keys(reviewData.value): []) as ReviewDataKey[];

        // Put keys in Formdata, but only if they are not in the active already
        if (keys) {
            keys.forEach((key) => {
                if (!activeKeys.includes(key)) {
                    formData.append('keys[]', key);
                }
            });
        }

        return FetchHelper.post<Partial<IReviewData>>(`wp-json/api/get-review-data/${reviewId}/`, formData).then((response) => {
            return updateReviewData(response);
        });
    },
});
