
import {defineComponent, PropType} from 'vue';
import {IUser} from '../../models/User';
import UserAvatar from '@/components/UserAvatar.vue';

export default defineComponent({
    name: 'CoordinatorUserInfo',
    components: {UserAvatar},
    props: {
        user: {type: Object as PropType<IUser>, required: true}
    }
})
