
import {defineComponent} from 'vue';
import PeerAvatar from './PeerAvatar.vue';
import useCurrentUser from '../composeables/useCurrentUser';

export default defineComponent({
    name: 'CurrentUserAvatar',
    components: {PeerAvatar},
    props: {
        iconOnly: {type: Boolean, default: false}
    },
    setup() {
        return {user: useCurrentUser().user};
    }
})
