import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { class: "main__content overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_GlobalModal = _resolveComponent("GlobalModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderMenu),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterView)
      ])
    ]),
    _createVNode(_component_GlobalModal)
  ]))
}