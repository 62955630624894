
import {defineComponent, ref} from 'vue';
import {FileUploadChangedEvent} from '../helpers/HelperTypes';

export default defineComponent({
    name: 'ImageUploadField',
    props: {
        currentImageUrl: {type: String, default: ''},
        name: {type: String, required: true},
        disabled: {type: Boolean, default: false},
    },
    emits: ['change'],
    setup() {
        const imageInput = ref<FileList|File[]>([]);
        const imageUrl = ref('');

        return {
            imageInput,
            imageUrl,
            handleImageUpload(e: FileUploadChangedEvent) {
                if (e.target.files.length === 1) {
                    imageUrl.value = URL.createObjectURL(e.target.files[0]);
                }
            }
        };
    }
})
