import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicFormField = _resolveComponent("DynamicFormField")!
  const _component_DataForm = _resolveComponent("DataForm")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_DataForm, { key: 0 }, {
        default: _withCtx(() => [
          (_ctx.data)
            ? (_openBlock(), _createBlock(_component_DynamicFormField, {
                key: 0,
                is: _ctx.component,
                data: _ctx.data
              }, null, 8, ["is", "data"]))
            : _createCommentVNode("", true),
          _createElementVNode("input", {
            type: "hidden",
            name: "keysToSet[]",
            value: _ctx.dataKey
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}