import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card--review__status" }
const _hoisted_2 = { class: "btn btn-secondary btn-pill" }
const _hoisted_3 = { class: "card--review__name" }
const _hoisted_4 = { class: "card-text" }
const _hoisted_5 = { class: "ml-auto d-flex" }
const _hoisted_6 = { class: "chevron-circle chevron-circle--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card--review mb-1", {'completed' : _ctx.isComplete}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.isComplete ? 'afgerond' : 'actief'), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.reviewName), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_icon, { icon: "chevronCircleLeft" })
        ])
      ])
    ]),
    (!_ctx.isComplete)
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          to: {name: 'PeerReviewSingle', params: {id: _ctx.reviewId}},
          class: "card__link"
        }, null, 8, ["to"]))
      : _createCommentVNode("", true)
  ], 2))
}