import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import mitt from 'mitt';

const emitter = mitt();
const app = createApp(App);
declare module '@vue/runtime-core' {

}

// Global Properties
app.config.globalProperties.emitter = emitter;
app.use(router).mount('#app');