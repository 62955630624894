
import {defineComponent, PropType} from 'vue';
import BaseBadge from './badges/BaseBadge.vue';
import Icon from './standalone/Icon.vue';
import {ITask} from '../models/Task';
import {useRouter} from 'vue-router'

export default defineComponent({
    name: 'TaskCard',
    components: {Icon, BaseBadge},
    props: {
        task: {type: Object as PropType<ITask>, required: true}
    },
    setup(props) {
        const router = useRouter()

        return {
            toTask() {
                router.push({
                    name: 'TaskSingle',
                    params: {reviewId: props.task.reviewId, id: props.task.id}
                });
            }
        }
    }
})
