
import {defineComponent, PropType, ref, watch} from 'vue';
import {IFileInfo} from '../models/FileInfo';
import {FileUploadChangedEvent} from '../helpers/HelperTypes';
import useActiveTask from '../composeables/useActiveTask';

export default defineComponent({
    name: 'DocumentUploadField',
    props: {
        currentDocument: {type: Object as PropType<IFileInfo>, default: undefined},
        name: {type: String, required: true},
        disabled: {type: Boolean, default: false}
    },
    emits: ['change'],
    setup(props) {
        const activeDocument = ref({name: props.currentDocument?.name, url: props.currentDocument?.url});

        watch(useActiveTask().errors, async (errors) => {
            if (errors.length) {
                activeDocument.value = {name: undefined, url: undefined};
            }
        });

        return {
            activeDocument,
            handleDocumentUpload(e: FileUploadChangedEvent) {
                const file = e.target.files[0];

                if (file) {
                    activeDocument.value = {
                        name: file.name,
                        url: URL.createObjectURL(file)
                    }
                }
            }
        };
    }
})
