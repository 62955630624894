
import {defineComponent, PropType} from 'vue';

const icons = {
    chevronTop: {
        x: 24,
        y: 24,
        path: 'M16.22 15.28a.75.75 0 001.06-1.06l-5-5a.75.75 0 00-1.06 0l-5 5a.75.75 0 001.06 1.06l4.47-4.47 4.47 4.47z'
    },
    chevronRight: {
        x: 24,
        y: 24,
        path: 'M9.22 16.22a.75.75 0 001.06 1.06l5-5a.75.75 0 000-1.06l-5-5a.75.75 0 00-1.06 1.06l4.47 4.47-4.47 4.47z'
    },
    chevronDown: {
        x: 24,
        y: 24,
        path: 'M7.28 9.22a.75.75 0 00-1.06 1.06l5 5a.75.75 0 001.06 0l5-5a.75.75 0 00-1.06-1.06l-4.47 4.47-4.47-4.47z'
    },
    chevronLeft: {
        x: 24,
        y: 24,
        path: 'M15.28 7.28a.75.75 0 00-1.06-1.06l-5 5a.75.75 0 000 1.06l5 5a.75.75 0 001.06-1.06l-4.47-4.47 4.47-4.47z'
    },
    chevronCircleLeft: {
        x: 20,
        y: 20,
        path: 'M11.25 15c-.384 0-.768-.146-1.061-.439l-3.5-3.5a1.5 1.5 0 010-2.121l3.5-3.5a1.5 1.5 0 112.121 2.121L9.871 10l2.439 2.439A1.5 1.5 0 0111.25 15z'
    },
    blockList: {
        x: 18,
        y: 18,
        path: 'M15,18 L3,18 C1.3,18 0,16.7 0,15 L0,3 C0,1.3 1.3,0 3,0 L15,0 C16.7,0 18,1.3 18,3 L18,15 C18,16.7 16.7,18 15,18 Z'
    },
    blockListItem: {
        x: 18,
        y: 18,
        path: 'M10,12 C10.5571429,12 10.9418367,12.344898 10.9939504,12.8745627 L11,13 C11,13.6 10.6,14 10,14 L5,14 C4.44285714,14 4.05816327,13.655102 4.00604956,13.1254373 L4,13 C4,12.4 4.4,12 5,12 L10,12 Z M13,8 C13.6,8 14,8.4 14,9 C14,9.6 13.6,10 13,10 L5,10 C4.4,10 4,9.6 4,9 C4,8.4 4.4,8 5,8 L13,8 Z M13,4 C13.6,4 14,4.4 14,5 C14,5.6 13.6,6 13,6 L5,6 C4.4,6 4,5.6 4,5 C4,4.4 4.4,4 5,4 L13,4 Z'
    },
    blockFile: {
        x: 18,
        y: 6,
        path: 'M18,12 L18,15 C18,16.657 16.657,18 15,18 L3,18 C1.343,18 0,16.657 0,15 L0,12 L4.342,12 C5.143,12 5.924,12.431 6.235,13.169 C6.69,14.246 7.755,15 9,15 C10.245,15 11.31,14.246 11.765,13.169 C12.076,12.431 12.856,12 13.658,12'
    },
    blockFlower: {
        x: 20,
        y: 20,
        path: 'M6.649,1.909 C7.22,0.817 8.527,0 10,0 C11.473,0 12.781,0.816 13.351,1.909 C14.527,1.54 16.028,1.887 17.071,2.929 C18.113,3.971 18.46,5.472 18.091,6.649 C19.183,7.22 20,8.527 20,10 C20,11.473 19.184,12.78 18.091,13.351 C18.46,14.527 18.113,16.028 17.071,17.071 C16.029,18.113 14.528,18.46 13.351,18.091 C12.78,19.183 11.473,20 10,20 C8.527,20 7.22,19.184 6.649,18.091 C5.473,18.46 3.971,18.113 2.929,17.071 C1.887,16.029 1.54,14.527 1.909,13.351 C0.817,12.78 0,11.473 0,10 C0,8.527 0.816,7.219 1.909,6.649 C1.54,5.473 1.887,3.972 2.929,2.929 C3.971,1.887 5.473,1.541 6.649,1.909 Z'
    },
    blockCheck: {
        x: 10,
        y: 7,
        path: 'M8.658,14.012 C8.402,14.012 8.146,13.914 7.951,13.719 L4.951,10.719 C4.56,10.328 4.56,9.696 4.951,9.305 C5.342,8.914 5.974,8.914 6.365,9.305 L8.658,11.598 L12.951,7.305 C13.342,6.914 13.974,6.914 14.365,7.305 C14.756,7.696 14.756,8.328 14.365,8.719 L9.365,13.719 C9.17,13.914 8.914,14.012 8.658,14.012 Z'
    },
    longArrowTop: {
        x: 24,
        y: 24,
        path: 'M7.22 9.28a.75.75 0 010-1.06l4-4a.75.75 0 011.06 0l4 4a.75.75 0 01-1.06 1.06L12.5 6.561V18.75a.75.75 0 01-.648.743l-.102.007a.75.75 0 01-.75-.75V6.561l-2.72 2.72a.75.75 0 01-.976.072z'
    },
    longArrowRight: {
        x: 24,
        y: 24,
        path: 'M14.22 7.22a.75.75 0 011.06 0l4 4a.75.75 0 010 1.06l-4 4a.75.75 0 01-1.06-1.06l2.719-2.72H4.75a.75.75 0 01-.743-.648L4 11.75a.75.75 0 01.75-.75h12.189l-2.72-2.72a.75.75 0 01-.072-.976z'
    },
    longArrowDown: {
        x: 24,
        y: 24,
        path: 'M7.22 15.28a.75.75 0 011.06-1.06L11 16.94V4.75a.75.75 0 01.648-.743L11.75 4a.75.75 0 01.75.75v12.19l2.72-2.72a.75.75 0 01.976-.073l.084.073a.75.75 0 010 1.06l-4 4-.072.064a.752.752 0 01-.458.156l.077-.004a.754.754 0 01-.06.004h-.017a.765.765 0 01-.42-.129.748.748 0 01-.11-.09l.078.068a.754.754 0 01-.078-.069z'
    },
    longArrowLeft: {
        x: 24,
        y: 24,
        path: 'M8.22 7.22a.75.75 0 011.06 1.06L6.56 11h12.19a.75.75 0 01.743.648l.007.102a.75.75 0 01-.75.75H6.56l2.72 2.72a.75.75 0 01.073.976l-.073.084a.75.75 0 01-1.06 0l-4-4-.064-.072A.752.752 0 014 11.75l.004.077a.754.754 0 01-.004-.06v-.017a.765.765 0 01.129-.42.748.748 0 01.09-.11l-.068.078a.754.754 0 01.069-.078z'
    },
    checkCircle: {
        x: 16,
        y: 16,
        path: 'M10.817.78a.75.75 0 01-.545 1.398 6.25 6.25 0 103.874 4.687.75.75 0 111.475-.272A7.75 7.75 0 1110.817.781zm4.61-.396a.75.75 0 01.246.948l-.057.096-6.25 9a.75.75 0 01-1.074.166l-.08-.071-3.75-3.857a.75.75 0 01.993-1.12l.083.074 3.114 3.204L14.384.572a.75.75 0 011.044-.188z'
    },
    loading: {
        x: 122,
        y: 122,
        path: 'M94.754 14.534c8.844 0 16.014 7.17 16.014 16.012 0 8.844-7.17 16.015-16.014 16.015-8.843 0-16.013-7.17-16.013-16.015 0-8.842 7.17-16.012 16.013-16.012zm14.511 37.587c-7.205 0-13.049 5.844-13.049 13.048 0 7.207 5.844 13.049 13.049 13.051 7.207 0 13.051-5.844 13.051-13.051-.001-7.204-5.844-13.048-13.051-13.048zm-15.13 37.782c-5.032 0-9.114 4.082-9.114 9.113 0 5.032 4.082 9.114 9.114 9.114 5.031 0 9.113-4.082 9.113-9.114 0-5.031-4.082-9.113-9.113-9.113zm-34.86 14.747c-5.032 0-9.114 4.081-9.114 9.113 0 5.034 4.082 9.116 9.114 9.116s9.113-4.082 9.113-9.116c.001-5.032-4.08-9.113-9.113-9.113zM23.652 90.86c-4.717 0-8.54 3.823-8.54 8.54 0 4.715 3.823 8.54 8.54 8.54 4.714 0 8.538-3.825 8.538-8.54 0-4.716-3.824-8.54-8.538-8.54zM9.096 54.872C4.072 54.872 0 58.944 0 63.968c0 5.021 4.072 9.093 9.096 9.093 5.021 0 9.093-4.072 9.093-9.093 0-5.024-4.073-9.096-9.093-9.096zm14.556-37.846c-6.354 0-11.508 5.155-11.508 11.509s5.154 11.506 11.508 11.506 11.506-5.152 11.506-11.506-5.152-11.509-11.506-11.509zM59.341 0C51.69 0 45.483 6.205 45.483 13.855c0 7.651 6.207 13.856 13.858 13.856s13.856-6.205 13.856-13.856C73.197 6.205 66.992 0 59.341 0z'
    },
    check: {
        x: 24,
        y: 24,
        path: 'M7.502 11.578a.857.857 0 10-1.29 1.13l3 3.428a.857.857 0 001.268.024l7.286-7.714a.857.857 0 10-1.246-1.177L9.88 14.297l-2.379-2.719z'
    },
} as const;

export default defineComponent({
    name: 'Icon',
    props: {
        icon: {type: String as PropType<keyof typeof icons>, required: true},
        size: {type: Number, default: 22}
    },
    setup(props) {
        return {
            path: icons[props.icon].path,
            viewbox: `0 0 ${icons[props.icon].x} ${icons[props.icon].y}`
        };
    }
})
