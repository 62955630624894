import {ReviewDataSchema} from '../models/ReviewData';
import {z} from 'zod';
import {TaskSchema} from '../models/Task';

export const FullTaskSchema = TaskSchema.extend({
    _nonce: z.string(),
    content: z.string(),
    buttonLabel: z.string(),
    buttonLabelAfterCompletion: z.string(),
    taskCompletedText: z.string(),
    status: z.union([z.literal('open'), z.literal('completed')]),
    taskComponent: z.string(),
    peerReviewId: z.number(),
    dataKeys: z.array(ReviewDataSchema.keyof()).optional()
});

export type IFullTask = z.infer<typeof FullTaskSchema>;