
import {defineComponent, PropType} from 'vue';
import {ITask} from '../models/Task';
import BaseBadge from '@/components/badges/BaseBadge.vue';

export default defineComponent({
    name: 'TaskEntry',
    components: {BaseBadge},
    props: {
        task: {type: Object as PropType<ITask>, required: true}
    }
})
