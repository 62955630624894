import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicFormField = _resolveComponent("DynamicFormField")!
  const _component_TaskForm = _resolveComponent("TaskForm")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.data)
    ? (_openBlock(), _createBlock(_component_TaskForm, {
        key: 0,
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.task.dataKeys, (metaKey) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: metaKey }, [
              (_ctx.data?.fields?.[metaKey]?.component)
                ? (_openBlock(), _createBlock(_component_DynamicFormField, {
                    key: 0,
                    is: _ctx.data?.fields[metaKey].component,
                    data: _ctx.data,
                    disabled: _ctx.task.isComplete || _ctx.isLoading
                  }, null, 8, ["is", "data", "disabled"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["onSubmit"]))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}