import {UserSchema} from '../models/User';
import z from 'zod';

export const PanelMemberSchema = z.object({
    user: UserSchema.nullish(),
    panelRole: z.string(),
    roleLabel: z.string(),
    partnershipId: z.number()
});

export type IPanelMember = z.infer<typeof PanelMemberSchema>;