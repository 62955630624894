import {computed, ref} from 'vue';
import Api from '../features/Api';
import {ITask, TaskSchema} from '../models/Task';
import z from 'zod';

const tasks = ref<ITask[]>();
let loadingTasks: Promise<void>|undefined;

export default (reviewId = 0) => {
    const refresh = () => {
        if (!loadingTasks) {
            tasks.value = undefined;

            loadingTasks = Api.get(`get-user-tasks/${reviewId}`).then((response) => {
                tasks.value = z.array(TaskSchema).parse(response.data);
            }).finally(() => {
                loadingTasks = undefined;
            });
        }

        return loadingTasks;
    }

    // Auto-refresh if not yet defined
    if (!tasks.value) {
        refresh();
    }

    return {
        tasks: computed(() => tasks.value),
        refresh,
        setTask(newTask: ITask) {
            if (tasks.value) {
                for (let i = 0; i < tasks.value.length; i++) {
                    if (tasks.value[i].id === newTask.id) {
                        tasks.value[i] = newTask;
                        return true;
                    }
                }
            }

            return false;
        },
        countActiveTasks: computed(() => (tasks.value) ? tasks.value.filter((task) => !task.isComplete).length : 0)
    }
}