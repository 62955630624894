
import {computed, defineComponent} from 'vue';
import TaskForm from '@/form/TaskForm.vue';
import useReceivedInvites from '../../composeables/useReceivedInvites';
import useActiveTask from '../../composeables/useActiveTask';
import {IInviteRequest} from '../../models/InviteRequest';
import InviteField from '@/components/invites/InviteField.vue';

export default defineComponent({
    name: 'AcceptInviteTask',
    components: {InviteField, TaskForm},
    setup() {
        useReceivedInvites().load();

        return {
            task: useActiveTask().activeTask,
            invites: computed(() => {
                const invites = useReceivedInvites().partnershipInvites;
                const reviewId = useActiveTask().reviewId.value;
                const recipientPartnershipId = useActiveTask().activeTask.value?.recipientPartnershipId || undefined;

                let mainInvite: IInviteRequest|undefined = undefined;
                const otherInvites: IInviteRequest[] = [];

                if (invites?.value.length && reviewId) {
                    for (const invite of invites?.value) {
                        if (invite.peerReviewId === reviewId && invite.invitedPartnership.id === recipientPartnershipId) {
                            mainInvite = invite;
                        } else if (invite.status === 'pending' && !invite.trashed) {
                            otherInvites.push(invite);
                        }
                    }

                    if (mainInvite) {
                        return {mainInvite, otherInvites};
                    }
                }

                return undefined;
            }),
            onSubmit(form: HTMLFormElement) {
                useActiveTask().submitTask(new FormData(form)).then((isSuccess) => {
                    if (isSuccess) {
                        useReceivedInvites().load();
                    }
                });
            }
        }
    }
})
