import {computed, ref} from 'vue';
import Api from '../features/Api';
import {FullThreadSchema, IFullThread} from '../models/FullThread';

const activeThread = ref<IFullThread>();
let loadingThread: Promise<void>|undefined;

export default () => {
    const openThread = (reviewId: number, threadId: number) => {
        if (!loadingThread) {
            activeThread.value = undefined;

            loadingThread = Api.get(`get-user-thread/${threadId}`).then((response) => {
                activeThread.value = (response.data) ? FullThreadSchema.parse(response.data) : undefined;
                loadingThread = undefined;
            });
        }

        return loadingThread;
    }

    const closeThread = () => {
        activeThread.value = undefined;
    }

    return {
        activeThread: computed(() => activeThread.value),
        openThread,
        closeThread
    }
}