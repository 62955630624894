
import {computed, defineComponent} from 'vue';
import CustomHeading from '../../components/CustomHeading.vue';
import InboxEntry from '../../components/InboxEntry.vue';
import {useRoute} from 'vue-router';
import ProgressSpinner from '../../components/standalone/ProgressSpinner.vue';
import useThreads from '../../composeables/useThreads';
import ReviewFilter from '@/components/filters/ReviewFilter.vue';

export default defineComponent({
    name: 'Inbox',
    components: {ReviewFilter, CustomHeading, ProgressSpinner, InboxEntry},
    setup() {
        const isSubpage = computed(() => useRoute().params && 'id' in useRoute().params);

        return {
            isSubpage,
            threads: useThreads().threads,
            changeReviewFilter(reviewId: number) {
                useThreads(reviewId).refresh();
            }
        };
    }
})
