import z from 'zod';

export const UserSchema = z.object({
    id: z.number(),
    email: z.string().email(),
    name: z.string(),
    phoneNumber: z.string(),
    avatarUrl: z.string(),
    duty: z.string(),
    role: z.string(),
    linkedInUrl: z.string(),
    hasWriteAccess: z.boolean()
});

export const VisitingCoordinatorSchema = UserSchema.extend({
    isOnPanel: z.boolean()
});

export type IUser = z.infer<typeof UserSchema>;
export type IVisitingCoordinator = z.infer<typeof VisitingCoordinatorSchema>;