import {ContactPersonSchema, IContactPerson} from '../models/ContactPerson';
import {computed, ref} from 'vue';
import Api from '../features/Api';
import z from 'zod';

const contactPersons = ref<IContactPerson[]>();

export default () => {
    return {
        contactPersons: computed(() => contactPersons.value ?? []),
        loadContactPersons(reviewId: number, force = false) {
            if (contactPersons.value && !force) {
                return Promise.resolve(contactPersons.value);
            }

            contactPersons.value = undefined;

            return Api.get(`get-contact-persons/${reviewId}`).then((response) => {
                contactPersons.value = z.array(ContactPersonSchema).parse(response.data);
            });
        }
    }
}
