import {computed, ref} from 'vue';
import Api from '../features/Api';
import {IThread, ThreadSchema} from '../models/Thread';
import z from 'zod';

const threads = ref<IThread[]>();
let loadingThreads: Promise<void>|undefined;

export default (reviewId = 0) => {
    const refresh = () => {
        if (!loadingThreads) {
            threads.value = undefined;

            loadingThreads = Api.get(`get-user-threads/${reviewId}`).then((response) => {
                threads.value = z.array(ThreadSchema).parse(response.data);
            }).finally(() => {
                loadingThreads = undefined;
            });
        }

        return loadingThreads;
    }

    // Auto-refresh if not yet defined
    if (!threads.value) {
        refresh();
    }

    return {
        threads: computed(() => threads.value),
        unreadMessages: computed(() => {
            let unread = 0;

            if (threads.value) {
                for(const thread of threads.value) {
                    unread += thread.unreadMessages;
                }
            }

            return unread;
        }),
        refresh
    }
}