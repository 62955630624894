import {UserSchema, VisitingCoordinatorSchema} from '../models/User';
import {PanelMemberSchema} from '../models/PanelMember';
import {DetailedPartnershipSchema} from '../features/interfaces';
import z from 'zod';
import {FileInfoSchema} from '../models/FileInfo';

const FieldConfigSchema = z.object({
    editable: z.boolean(),
    component: z.coerce.string()
});

export type ReviewDataKey = keyof IReviewData;

export const ReviewDataSchema = z.object({
    id: z.coerce.number(),
    fields: z.record(FieldConfigSchema),
    start_date: z.string().optional(),
    timeslot: z.object({
        id: z.coerce.number(),
        startDate: z.coerce.string(),
        endDate: z.coerce.string()
    }).optional(),
    receiving_partnership: DetailedPartnershipSchema.optional(),
    visiting_partnerships: z.object({
        first: DetailedPartnershipSchema.nullish(),
        second: DetailedPartnershipSchema.nullish(),
        firstStatus: z.union([z.literal('pending'), z.literal('accepted')]).nullish(),
        secondStatus: z.union([z.literal('pending'), z.literal('accepted')]).nullish()
    }).optional().default({}),
    review_report_concept: z.object({
        document: FileInfoSchema,
        comment: z.string()
    }).optional(),
    review_report_feedback: z.object({
        document: FileInfoSchema,
        comment: z.string()
    }).optional(),
    review_report_definitive: z.object({
        document: FileInfoSchema
    }).optional(),
    preparation_document: FileInfoSchema.optional(),
    preliminary_discussion: z.object({
        date: z.coerce.string().nullish(),
        startTime: z.coerce.string().nullish(),
        endTime: z.coerce.string().nullish(),
        locationType: z.coerce.string().nullish(),
        locationDetails: z.coerce.string().nullish()
    }).optional().default({}),
    critical_reflection: z.object({
        document: FileInfoSchema,
        attachments: z.array(FileInfoSchema)
    }).optional(),
    peer_review_day_program: z.object({
        startTime: z.coerce.string(),
        endTime: z.coerce.string(),
        locationDetails: z.coerce.string(),
        documents: z.array(FileInfoSchema)
    }).optional(),
    receiving_coordinators: z.array(UserSchema).optional().default([]),
    visiting_coordinators_1: z.array(VisitingCoordinatorSchema).optional().default([]),
    visiting_coordinators_2: z.array(VisitingCoordinatorSchema).optional().default([]),
    panel_member_roles: z.record(z.union([z.literal(1), z.literal(2)])).optional(),
    panel_members_1: z.array(PanelMemberSchema).optional().default([]),
    panel_members_2: z.array(PanelMemberSchema).optional().default([]),
    datepicker_url: z.string().optional(),
});

export type IReviewData = z.infer<typeof ReviewDataSchema>;