
import {defineComponent} from 'vue';
import ThreadMessage from '../../components/ThreadMessage.vue';
import TaskCard from '../../components/TaskCard.vue';
import useActiveThread from '../../composeables/useActiveThread';
import UserAvatar from '@/components/UserAvatar.vue';

export default defineComponent({
    name: 'InboxSingle',
    components: {UserAvatar, TaskCard, ThreadMessage},
    props: {
        id: {type: Number, required: true},
        reviewId: {type: Number, required: true}
    },
    setup(props) {
        useActiveThread().closeThread();
        useActiveThread().openThread(props.reviewId, props.id);

        return {
            thread: useActiveThread().activeThread
        }
    }
})
