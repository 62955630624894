import {z} from 'zod';

export const TimeslotSchema = z.object({
    id: z.number(),
    startDate: z.string(),
    endDate: z.string(),
    startDateFormatted: z.string(),
    endDateFormatted: z.string(),
    count: z.number()
});

export type ITimeslot = z.infer<typeof TimeslotSchema>;