import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseBadge = _resolveComponent("BaseBadge")!

  return (_ctx.unreadMessages)
    ? (_openBlock(), _createBlock(_component_BaseBadge, {
        key: 0,
        type: "danger"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.unreadMessages), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}