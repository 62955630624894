
import {defineComponent, PropType} from 'vue';
import {IPanelMember} from '../../models/PanelMember';
import UserAvatar from '@/components/UserAvatar.vue';

export default defineComponent({
    name: 'PanelMemberUserInfo',
    components: {UserAvatar},
    props: {
        panelMember: {type: Object as PropType<IPanelMember>, required: true}
    }
})
