import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "column-1 d-flex align-items-center mb-3"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  key: 0,
  class: "h6 mb-0"
}
const _hoisted_4 = { class: "column-11" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isFirst)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.message.sender?.name), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", {
        innerHTML: _ctx.message.body
      }, null, 8, _hoisted_5),
      _createElementVNode("time", {
        class: "d-inline-block align-self-end",
        datetime: _ctx.message.createdAt
      }, _toDisplayString(_ctx.message.createdAt), 9, _hoisted_6)
    ])
  ], 64))
}