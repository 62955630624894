
import {defineComponent, PropType} from 'vue';
import EditReviewDataButton from '@/form/EditReviewDataButton.vue';
import {IFileInfo} from '../../models/FileInfo';
import {ReviewDataKey} from '../../models/ReviewData';

export default defineComponent({
    name: 'BsDocumentCard',
    components: {EditReviewDataButton},
    props: {
        title: {type: String, required: true},
        document: {type: Object as PropType<IFileInfo|null>, default: undefined},
        dataKey: {type: String as PropType<ReviewDataKey>, required: true}
    }
})
