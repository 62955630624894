export default class FetchHelper {
    public static async post<T>(target: string, body: BodyInit|undefined = undefined): Promise<T> {
        const rawResponse = await fetch(`${window.location.origin}/${target}`, {method: 'POST', body});
        return rawResponse.json();
    }

    public static async get<T>(target: string): Promise<T> {
        const rawResponse = await fetch(`${window.location.origin}/${target}`, {method: 'GET'});
        return rawResponse.json();
    }
}
