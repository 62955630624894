
import {defineComponent, PropType} from 'vue';
import {IUser} from '../../models/User';
import CoordinatorUserInfo from '@/components/peerreview/CoordinatorUserInfo.vue';

export default defineComponent({
    name: 'CoordinatorList',
    components: {CoordinatorUserInfo},
    props: {
        coordinators: {type: Array as PropType<IUser[]>, required: true}
    }
})
