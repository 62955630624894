import {UserSchema} from './User';
import {z} from 'zod';

export const MessageSchema = z.object({
    body: z.string(),
    createdAt: z.string(),
    id: z.number(),
    status: z.union([z.literal('read'), z.literal('unread')]),
    sender: z.union([UserSchema, z.undefined(), z.null()])
});

export type IMessage = z.infer<typeof MessageSchema>;