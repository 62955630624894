import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "prev-page-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.$route.matched.length > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          to: _ctx.$route.matched[0],
          class: "btn btn-prev"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              icon: "longArrowLeft",
              fill: "currentColor"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]))
    : _createCommentVNode("", true)
}