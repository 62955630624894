
import {computed, defineComponent, PropType} from 'vue';
import {IPanelMember} from '../../models/PanelMember';
import {IReviewData} from '../../models/ReviewData';
import useRoleLabels from '../../composeables/useRoleLabels';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';

export default defineComponent({
    name: 'SelectPanelMemberRolesField',
    components: {ProgressSpinner},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    setup(props) {
        const roleLabels = useRoleLabels().get();

        return {
            panelMembers: computed(() => {
                const panelMembers: Record<string, IPanelMember> = {};
                for (const member of [...(props.data.panel_members_1 || []), ...(props.data.panel_members_2 || [])]) {
                    panelMembers[member.panelRole] = member;
                }

                return panelMembers;
            }),
            reviewerRoles: computed(() => {
                return roleLabels.value ? Object.freeze({
                    'eindverantwoordelijke_opleidingsschool': roleLabels.value.schoolRepresentative,
                    'instituutsopleider': roleLabels.value.instituteTrainer,
                    'schoolopleider': roleLabels.value.schoolTrainer,
                    'werkplekbegeleider': roleLabels.value.workplaceSupervisor,
                    'student': roleLabels.value.student
                }) : undefined;
            })
        }
    }
})
