import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "form-group textarea--limited" }
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = ["name", "disabled"]
const _hoisted_4 = {
  key: 2,
  class: "text-right text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.disabled)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 0,
          name: _ctx.name,
          value: _ctx.value,
          class: "form-control",
          disabled: ""
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          name: _ctx.name,
          class: "form-control",
          disabled: _ctx.disabled,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
        }, null, 40, _hoisted_3)),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.limit - _ctx.text.length), 1))
      : _createCommentVNode("", true)
  ]))
}