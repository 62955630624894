import axios, {AxiosRequestConfig} from 'axios';

export default class Api {
    private static sessionExpired = false;

    public static get(endpoint: string, queryArgs: object = {}) {
        return Api.request(endpoint, queryArgs);
    }

    public static post(endpoint: string, data: object, nonce?: string) {
        return Api.request(endpoint, data, nonce ?? 'POST');
    }

    /** Performs a GET request unless a nonce is provided, in which case it will perform a POST request */
    private static request(endpoint: string, data: object, nonce?: string) {
        // Build the URL
        const origin = (window.location.origin.includes('localhost')) ? `http://${window.location.host}` : window.location.origin;
        let urlStr = `${origin}/wp-json/api/${endpoint}/`;

        // Build the config
        const config: AxiosRequestConfig = {};
        if (nonce) {
            config.method = 'POST';
            config.data = data;
            if (nonce !== 'POST') {
                config.headers = {'content-type': 'application/json', 'X-WP-Nonce': nonce};
            }
        } else {
            config.method = 'GET';

            let char = '?';
            Object.entries(data).forEach(([queryKey, queryArg]) => {
                if (queryArg) {
                    urlStr += `${char}${queryKey}=${queryArg}`;
                    char = '&';
                }
            });
        }

        config.url = urlStr;

        const promise = axios.request<unknown>(config);

        promise.catch((error) => {
            if (!Api.sessionExpired) {
                if (error.response.status === 401) {
                    Api.sessionExpired = true;
                    window.location.replace(`${window.location.origin}/wp-login.php`);
                    throw 'Access was denied, redirecting to login page...';
                } else if (error.response.status === 403) {
                    Api.sessionExpired = true;
                    console.error(JSON.stringify(error.response));
                    window.location.replace(`${window.location.origin}/wp-admin`);
                    throw 'Access was denied, redirecting to admin page...';
                } else {
                    window.alert(`${error.response.status}: ${error.response.statusText}`);
                }
            }
        });

        return promise;
    }
}