
import {defineComponent, PropType} from 'vue';
import {IReviewData} from '../models/ReviewData';
import SelectReceivingCoordinatorsField from '@/form/fieldsets/SelectReceivingCoordinatorsField.vue';
import SelectPeerReviewDateField from '@/form/fieldsets/SelectPeerReviewDateField.vue';
import UploadConceptReviewReportField from '@/form/fieldsets/UploadConceptReviewReportField.vue';
import SelectPreliminaryDiscussionDatetimeAndLocationField from '@/form/fieldsets/SelectPreliminaryDiscussionDatetimeAndLocationField.vue';
import SelectTimeslotField from '@/form/fieldsets/SelectTimeslotField.vue';
import PreliminaryDiscussionChecklistField from '@/form/fieldsets/PreliminaryDiscussionChecklistField.vue';
import SetDatePickerUrlField from '@/form/fieldsets/SetDatePickerUrlField.vue';
import UploadReviewReportFeedbackField from '@/form/fieldsets/UploadReviewReportFeedbackField.vue';
import UploadCriticalReflectionField from '@/form/fieldsets/UploadCriticalReflectionField.vue';
import UploadReviewDayDocumentsField from '@/form/fieldsets/UploadReviewDayDocumentsField.vue';
import SelectSecondVisitingCoordinatorsField from '@/form/fieldsets/SelectSecondVisitingCoordinatorsField.vue';
import SelectFirstVisitingCoordinatorsField from '@/form/fieldsets/SelectFirstVisitingCoordinatorsField.vue';
import SelectPanelMemberRolesField from '@/form/fieldsets/SelectPanelMemberRolesField.vue';
import SelectFirstPanelMembersField from '@/form/fieldsets/SelectFirstPanelMembersField.vue';
import SelectSecondPanelMembersField from '@/form/fieldsets/SelectSecondPanelMembersField.vue';
import UploadDefinitiveReviewReportField from '@/form/fieldsets/UploadDefinitiveReviewReportField.vue';


export default defineComponent({
    name: 'DynamicFormField',
    components: {
        SelectPanelMemberRolesField,
        SelectFirstPanelMembersField,
        SelectSecondPanelMembersField,
        SelectReceivingCoordinatorsField,
        SelectFirstVisitingCoordinatorsField,
        SelectSecondVisitingCoordinatorsField,
        UploadCriticalReflectionField,
        SelectPeerReviewDateField,
        UploadConceptReviewReportField,
        SelectTimeslotField,
        PreliminaryDiscussionChecklistField,
        SelectPreliminaryDiscussionDatetimeAndLocationField,
        SetDatePickerUrlField,
        UploadReviewReportFeedbackField,
        UploadDefinitiveReviewReportField,
        UploadReviewDayDocumentsField
    },
    props: {
        is: {type: String, required: true},
        data: {type: Object as PropType<IReviewData>, required: true},
        disabled: {type: Boolean, default: false}
    }
})
