
import {defineComponent} from 'vue';
import Icon from '../../components/standalone/Icon.vue';
import NewMessagesBadge from '../../components/badges/NewMessagesBadge.vue';
import NewTasksBadge from '../../components/badges/NewTasksBadge.vue';
import CurrentUserAvatar from '../../components/CurrentUserAvatar.vue';
import useCurrentUser from '../../composeables/useCurrentUser';

export default defineComponent({
    name: 'HeaderMenu',
    components: {CurrentUserAvatar, NewTasksBadge, NewMessagesBadge, Icon},
    setup() {
        return {
            user: useCurrentUser().user
        }
    }
})
