import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-md-12" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  value: ""
}
const _hoisted_6 = ["selected", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.roleLabels?.programLeader), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("select", {
            ref: "programLeader",
            class: "form-control",
            name: "programLeader",
            disabled: _ctx.disabled,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateProgramLeader()))
          }, [
            (_ctx.canSelectNobody)
              ? (_openBlock(), _createElementBlock("option", _hoisted_5, "(Er neemt niemand deel aan het panel)"))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coordinators, (person, key) => {
              return (_openBlock(), _createElementBlock("option", {
                key: key,
                selected: person.isOnPanel,
                value: person.id
              }, _toDisplayString(person.name) + " (" + _toDisplayString(person.email) + ") ", 9, _hoisted_6))
            }), 128))
          ], 40, _hoisted_4)
        ])
      ])
    ])
  ]))
}