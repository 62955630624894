import {computed, ref} from 'vue';
import Api from '../features/Api';
import {IInviteRequest, InviteRequestSchema} from '../models/InviteRequest';
import {IPartnershipChoice, PartnershipChoiceSchema} from '../models/Partnership';
import useActiveTask from './useActiveTask';
import z from 'zod';

export enum PartnershipSelectionType {
    UNDEFINED, FREE, SHORTLIST
}

const invitedPartnerships = ref<Readonly<IInviteRequest>[]>();
const shortlist = ref<Readonly<IPartnershipChoice[]>>();

const refreshPromise = ref<Promise<void>>();
const selectPromise = ref<Promise<void>>();

const InviteSubmitResponseSchema = z.object({
    success: z.coerce.boolean(),
    invites: z.object({1: InviteRequestSchema.optional(), 2: InviteRequestSchema.optional()}).optional().default({}),
    shortlist: z.array(PartnershipChoiceSchema)
});

export type InviteSubmitResponse = z.infer<typeof InviteSubmitResponseSchema>;

export default () => {
    return {
        refresh(reviewId: number) {
            if (!refreshPromise.value) {
                invitedPartnerships.value = [];
                shortlist.value = [];

                refreshPromise.value = Api.get(`get-sent-invites-and-shortlist/${reviewId}`).then((response) => {
                    this.handleResponseData(InviteSubmitResponseSchema.parse(response.data));
                }).finally(() => {
                    refreshPromise.value = undefined;
                });
            }

            return refreshPromise.value;
        },
        selectPartnership(type: PartnershipSelectionType, partnership: IPartnershipChoice) {
            if (type !== PartnershipSelectionType.UNDEFINED && !selectPromise.value) {
                selectPromise.value = Api.get(`draft-invite/${useActiveTask().reviewId.value}/${type}/${partnership.id}`).then((response) => {
                    if (!invitedPartnerships.value) {
                        throw 'Selected partnerships array was not initialised.';
                    }

                    this.handleResponseData(InviteSubmitResponseSchema.parse(response.data));
                }).finally(() => {
                    selectPromise.value = undefined;
                });
            }
        },
        deselectPartnership(type: PartnershipSelectionType) {
            if (!selectPromise.value) {
                selectPromise.value = Api.get(`retract-invite/${useActiveTask().reviewId.value}/${type}`).then((response) => {
                    if (!invitedPartnerships.value) {
                        throw 'Selected partnerships array was not initialised.';
                    }

                    if (response.data) {
                        delete invitedPartnerships.value[type];
                    }
                }).finally(() => {
                    selectPromise.value = undefined;
                });
            }
        },
        trashShortlistEntry(reviewId: number, partnershipId: number) {
            Api.get(`trash-shortlist-entry/${reviewId}/${partnershipId}`).then((response) => {
                this.handleResponseData(InviteSubmitResponseSchema.parse(response.data));
            });
        },
        handleResponseData(responseData: InviteSubmitResponse) {
            invitedPartnerships.value = [];

            if (responseData.invites?.[1]) {
                invitedPartnerships.value[1] = Object.freeze(responseData.invites[1]);
            }

            if (responseData.invites?.[2]) {
                invitedPartnerships.value[2] = Object.freeze(responseData.invites[2]);
            }

            if (responseData.shortlist) {
                shortlist.value = Object.freeze(responseData.shortlist);
            }
        },
        invitedPartnerships: computed(() => invitedPartnerships.value ?? []),
        shortlist: computed(() => shortlist.value ?? []),
        isLoading: computed(() => (refreshPromise.value || selectPromise.value))
    }
}