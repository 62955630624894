import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipCard = _resolveComponent("PartnershipCard")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shortlist, (partnership, key) => {
    return (_openBlock(), _createBlock(_component_PartnershipCard, {
      key: key,
      partnership: partnership,
      "selectable-as": _ctx.partnershipType
    }, null, 8, ["partnership", "selectable-as"]))
  }), 128))
}