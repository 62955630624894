
import {defineComponent, PropType, ref} from 'vue';
import {IDetailedPartnership} from '../features/interfaces';

export default defineComponent({
    name: 'PartnershipIdentity',
    props: {
        partnership: {type: Object as PropType<IDetailedPartnership>, required: true}
    },
    setup(props) {
        const imageBlob = ref('');

        if (props.partnership.organizationChartUrl && props.partnership.organizationChartType === 'image') {
            fetch(props.partnership.organizationChartUrl, {method: 'GET', mode: 'cors'}).then((r) => r.blob()).then((blob) => {
                imageBlob.value = URL.createObjectURL(blob);
            });
        }

        return {imageBlob};
    }
})
