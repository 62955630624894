import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipIdentity = _resolveComponent("PartnershipIdentity")!

  return (_ctx.partnership)
    ? (_openBlock(), _createBlock(_component_PartnershipIdentity, {
        key: 0,
        partnership: _ctx.partnership
      }, null, 8, ["partnership"]))
    : _createCommentVNode("", true)
}