
import {computed, defineComponent} from 'vue';
import CustomHeading from '../../components/CustomHeading.vue';
import TaskEntry from '../../components/TaskEntry.vue';
import {useRoute} from 'vue-router';
import ProgressSpinner from '../../components/standalone/ProgressSpinner.vue';
import useTasks from '../../composeables/useTasks';
import ReviewFilter from '@/components/filters/ReviewFilter.vue';

export default defineComponent({
    name: 'Tasks',
    components: {ReviewFilter, CustomHeading, ProgressSpinner, TaskEntry},
    setup() {
        const isSubpage = computed(() => useRoute().params && 'id' in useRoute().params);

        return {
            isSubpage,
            tasks: useTasks().tasks,
            changeReviewFilter(reviewId: number) {
                useTasks(reviewId).refresh();
            }
        };
    }
})
