import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "btn btn-primary mt-3 disabled"
}
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskCompleteMessage = _resolveComponent("TaskCompleteMessage")!

  return (_ctx.task?.isComplete)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TaskCompleteMessage, { task: _ctx.task }, null, 8, ["task"]),
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.task?.buttonLabelAfterCompletion)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.task?.buttonLabelAfterCompletion), 1))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        ref: "form",
        class: "task-form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.emitSubmit && _ctx.emitSubmit(...args)), ["prevent"]))
      }, [
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.task?.buttonLabel), 1)
        ])
      ], 544))
}