
import {defineComponent} from 'vue';
import BaseBadge from './BaseBadge.vue';
import useThreads from '../../composeables/useThreads';

export default defineComponent({
    name: 'NewMessagesBadge',
    components: {BaseBadge},
    setup() {
      return {
          unreadMessages: useThreads().unreadMessages
      }
    }
})
