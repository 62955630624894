
import {defineComponent} from 'vue';
import BaseBadge from './BaseBadge.vue';
import useTasks from '../../composeables/useTasks';

export default defineComponent({
    name: 'NewTasksBadge',
    components: {BaseBadge},
    setup() {
        return {
            taskCount: useTasks().countActiveTasks
        }
    }
})
