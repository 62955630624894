
import {defineComponent} from 'vue';
import Icon from './standalone/Icon.vue';

export default defineComponent({
    name: 'PeerReviewEntry',
    components: {Icon},
    props: {
        reviewId: {type: Number, required: true},
        reviewName: {type: String, required: true},
        isComplete: {type: Boolean, default: false}
    }
})
