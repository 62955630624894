
import {defineComponent, PropType, ref} from 'vue';
import useActiveTask from '../composeables/useActiveTask';
import TaskForm from '@/form/TaskForm.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useReviewData from '../composeables/useReviewData';
import DynamicFormField from '@/form/DynamicFormField.vue';
import {IFullTask} from '../models/FullTask';

export default defineComponent({
    name: 'DataBoundTask',
    components: {DynamicFormField, ProgressSpinner, TaskForm},
    props: {
        task: {type: Object as PropType<IFullTask>, required: true}
    },
    setup(props) {
        const isLoading = ref(true);

        if (props.task.dataKeys) {
            useReviewData().load(props.task.reviewId, props.task.dataKeys);
            isLoading.value = false;
        } else {
            throw 'No task is being loaded.';
        }

        return {
            isLoading,
            data: useReviewData().reviewData,
            reviewId: useActiveTask().reviewId,
            onSubmit(form: HTMLFormElement) {
                isLoading.value = true;

                useActiveTask().submitTask(new FormData(form)).then(() => {
                    isLoading.value = false;
                });
            }
        }
    }
})
