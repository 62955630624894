import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "column-4 d-flex flex-column justify-content-start" }
const _hoisted_2 = {
  key: 0,
  class: "message-list no-scrollbar"
}
const _hoisted_3 = {
  key: 0,
  class: "py-3 text-center"
}
const _hoisted_4 = { class: "column-8 bg-image-white border-left overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomHeading = _resolveComponent("CustomHeading")!
  const _component_ReviewFilter = _resolveComponent("ReviewFilter")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_TaskEntry = _resolveComponent("TaskEntry")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustomHeading, {
      title: "Taken",
      class: "mb-0"
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["main__row no-scrollbar", {'subpage-open': _ctx.isSubpage}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ReviewFilter, {
          "label-prefix": "Taken",
          onChange: _ctx.changeReviewFilter
        }, null, 8, ["onChange"]),
        (!_ctx.tasks)
          ? (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "slide-fade-right" }, {
          default: _withCtx(() => [
            (_ctx.tasks)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.tasks.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, "U heeft momenteel geen taken."))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, key) => {
                    return (_openBlock(), _createBlock(_component_TaskEntry, {
                      key: key,
                      task: task
                    }, null, 8, ["task"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createBlock(_component_RouterView, {
          key: _ctx.$route.fullPath
        }))
      ])
    ], 2)
  ], 64))
}