
import {defineComponent, PropType} from 'vue';
import useTimeslots from '../../composeables/useTimeslots';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import {IReviewData} from '../../models/ReviewData';

export default defineComponent({
    name: 'SelectTimeslotField',
    components: {ProgressSpinner},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    setup(props) {
        useTimeslots().load(props.data.id);

        const getActivityStatus = (count: number) => {
            if (count > 20) {
                return 'busy';
            }

            if (count >= 10) {
                return 'average';
            }

            return 'quiet';
        };

        return {
            timeslots: useTimeslots().timeslots,
            getBracketClass(count: number) {
                return getActivityStatus(count);
            },
            getActivityStatus
        }
    }
})
