
import {computed, defineComponent, PropType} from 'vue';
import useCurrentUser from '../composeables/useCurrentUser';
import useDataEditor from '../composeables/useDataEditor';
import useModal from '../composeables/useModal';
import useReviewData from '../composeables/useReviewData';
import {ReviewDataKey} from '../models/ReviewData';

export default defineComponent({
    name: 'EditReviewDataButton',
    props: {
        dataKey: {type: String as PropType<ReviewDataKey>, required: true}
    },
    setup(props) {
        return {
            user: useCurrentUser().user,
            edit() {
                const fieldData = useReviewData().reviewData.value?.fields?.[props.dataKey];
                if (!fieldData) {
                    throw 'Field data was not defined.';
                }

                useDataEditor().open(fieldData.component, props.dataKey);
                useModal().open('DataEditorPage');
            },
            canEdit: computed(() => {
                return !!useReviewData().reviewData.value?.fields?.[props.dataKey]?.editable;
            })
        }
    }
})
