
import {defineComponent, ref} from 'vue';
import useReviewData from '../composeables/useReviewData';
import useModal from '../composeables/useModal';

export default defineComponent({
    name: 'DataForm',
    setup() {
        const form = ref<HTMLFormElement>();

        return {
            onSubmit() {
                const formData = new FormData(form.value);

                useReviewData().set(formData).then((response) => {
                   if (response.success) {
                       useModal().close();
                   } else {
                       window.alert(response.errors[0]);
                   }
                });
            },
            form
        };
    }
})
