import {computed, ref} from 'vue';
import {ReviewDataKey} from '../models/ReviewData';

const fieldComponent = ref('');
const valuesToEdit = ref<ReviewDataKey[]>([]);

export default () => ({
    fieldComponent: computed(() => fieldComponent.value),
    valueToEdit: computed(() => valuesToEdit.value[0] ?? undefined),
    open: (fieldComponentName: string, dataKey: ReviewDataKey) => {
        fieldComponent.value = fieldComponentName;
        valuesToEdit.value = [dataKey];
    }
})