
import {defineComponent, PropType} from 'vue';
import {IPartnershipChoice} from '../../../models/Partnership';

export default defineComponent({
    name: 'PartnershipInviteStatusBadge',
    props: {
        partnership: {type: Object as PropType<IPartnershipChoice>, required: true},
        inviteStatus: {type: String, required: true}
    }
})
