import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeerAvatar = _resolveComponent("PeerAvatar")!

  return (_openBlock(), _createBlock(_component_PeerAvatar, {
    user: _ctx.user,
    "show-button": !_ctx.iconOnly,
    "show-name": !_ctx.iconOnly
  }, null, 8, ["user", "show-button", "show-name"]))
}