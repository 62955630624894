import ApiResource from '../features/ApiResource';
import {computed} from 'vue';
import {z} from 'zod';
import {htmlDecode} from '../helpers/ZodHelper';

export const CompactReviewSchema = z.object({
    id: z.number(),
    recipient: z.string().transform(htmlDecode),
    status: z.string(),
    title: z.string().transform(htmlDecode)
});

const resource = new ApiResource('wp-json/api/get-user-peer-reviews/', (data) => (
    z.object({
        owned: z.array(CompactReviewSchema),
        visiting: z.array(CompactReviewSchema),
        global: z.array(CompactReviewSchema)
    }).parse(data)
));

export default () => ({
    reviews: resource.get(),
    combinedReviews: computed(() => {
        const reviews = resource.get().value;
        return (reviews) ? [...reviews.visiting, ...reviews.owned] : undefined
    }),
    load: resource.load,
});