
import {defineComponent, PropType} from 'vue';

type BootstrapAlert = 'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark';

export default defineComponent({
    name: 'BaseBadge',
    props: {
        type: {type: String as PropType<BootstrapAlert>, default: 'primary'}
    },
})
