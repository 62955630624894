
import {defineComponent, PropType} from 'vue';
import {IReviewData} from '../../models/ReviewData';
import PanelMemberInput from '@/components/tasks/selectPanelMembersTask/PanelMemberInput.vue';
import ProgramLeaderInput from '@/components/tasks/selectPanelMembersTask/ProgramLeaderInput.vue';

export default defineComponent({
    name: 'SelectFirstPanelMembersField',
    components: {ProgramLeaderInput, PanelMemberInput},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true},
    }
})
