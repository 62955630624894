
import {defineComponent, ref} from 'vue';
import usePartnership from '../../../composeables/usePartnership';
import {PartnershipSelectionType} from '../../../composeables/usePartnershipInvites';
import PartnershipCard from '../../PartnershipCard.vue';
import ProgressSpinner from '../../standalone/ProgressSpinner.vue';
import useEducationSectors from '../../../composeables/useEducationSectors';
import {IPartnershipChoice, PartnershipChoiceSchema} from '../../../models/Partnership';
import Api from '../../../features/Api';
import useActiveTask from '../../../composeables/useActiveTask';
import {z} from 'zod';

export default defineComponent({
    name: 'FreePeerReviewVisitorSelect',
    components: {PartnershipCard, ProgressSpinner},
    setup() {
        let scrollDebouncer = 0;
        let filterDebouncer = 0;

        const noPartnersLeft = ref(false);
        const isLoadingPages = ref(false);
        const partnerPages = ref<IPartnershipChoice[][]>([]);
        const filterForm = ref<HTMLFormElement>();

        const fetchNextPage = () => {
            isLoadingPages.value = true;

            const object: Record<string, string> = {};
            (new FormData(filterForm.value)).forEach((value, key) => {
                if (typeof value === 'string') {
                    object[key] = value;
                }
            });

            Api.get(`get-partnership-choices/${useActiveTask().reviewId.value}/${partnerPages.value.length + 1}`, object).then((response) => {
                const data = z.array(PartnershipChoiceSchema).parse(response.data);

                if (data.length > 0) {
                    partnerPages.value.push(data);
                } else if (data.length < 15) {
                    noPartnersLeft.value = true;
                }

                isLoadingPages.value = false;
            });
        };

        fetchNextPage();

        return {
            filterForm,
            partnerPages,
            isLoadingPages,
            noPartnersLeft,
            educationSectorOptions: useEducationSectors().educationSectors,
            partnershipType: PartnershipSelectionType.FREE,
            activePartnership: usePartnership().activePartnership,
            changeFilters() {
                clearTimeout(filterDebouncer);

                filterDebouncer = setTimeout(() => {
                    partnerPages.value = [];
                    noPartnersLeft.value = false;
                    fetchNextPage();
                }, 200);
            },
            onScroll(e: Event) {
                if (noPartnersLeft.value) {
                    return;
                }

                clearTimeout(scrollDebouncer);
                scrollDebouncer = setTimeout(() => {
                    const elm = e.target as HTMLDivElement;
                    const isScrolledToBottom = (elm.scrollTop + 5 >= elm.scrollHeight - elm.offsetHeight);

                    if (isScrolledToBottom && !isLoadingPages.value) {
                        fetchNextPage();
                    }
                }, 100);
            }
        };
    }
});
