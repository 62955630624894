
import {defineComponent} from 'vue';
import ProgressSpinner from './standalone/ProgressSpinner.vue';
import usePartnership from '../composeables/usePartnership';
import useEducationSectors from '../composeables/useEducationSectors';
import PartnershipIdentity from '@/components/PartnershipIdentity.vue';

export default defineComponent({
    name: 'PartnershipDetail',
    components: {PartnershipIdentity, ProgressSpinner},
    setup() {
        return {
            sectors: useEducationSectors().educationSectors,
            partnership: usePartnership().activePartnership,
        }
    }
})
