import {computed, ref} from 'vue';
import Api from '../features/Api';
import {IInviteRequest, InviteRequestSchema} from '../models/InviteRequest';
import useActiveTask from '../composeables/useActiveTask';
import z from 'zod';

const partnershipInvites = ref<Readonly<IInviteRequest>[]>();
let refreshPromise: Promise<void>|undefined;

export default () => {
    const load = () => {
        if (!refreshPromise) {
            partnershipInvites.value = [];

            refreshPromise = Api.get(`get-invites/${useActiveTask().reviewId.value}`).then((response) => {
                partnershipInvites.value = z.array(InviteRequestSchema).parse(response.data);
            }).finally(() => {
                refreshPromise = undefined;
            });
        }

        return refreshPromise;
    };

    return {
        load,
        partnershipInvites: computed(() => partnershipInvites.value ?? []),
    }
}